import styled from 'styled-components'

const Image = styled.img`
  cursor: pointer;
`

export default function PlayButton(props) {
  return(
    <Image src={`${process.env.REACT_APP_CDN_URL}/assets/images/animations/moon-play.png`} alt="Iniciar" style={{ maxWidth: '300px' }} {...props} />
  )
}