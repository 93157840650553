import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

const PlayerContext = createContext();

const PlayerProvider = ({ children }) => {
  const [showPlayer, setShowPlayer] = useState(false);
  const playerRef = useRef(null);
  const [chapter, setChapter] = useState(0);
  const chapterRef = useRef();
  const [sources, setSources] = useState([]);
  const [tracks, setTracks] = useState([]);

  const addChapter = () => {
    setChapter(prevIndex => prevIndex + 1);
  }

  const removeChapter = () => {
    setChapter(prevIndex => prevIndex - 1);
  }

  chapterRef.current = chapter

  useEffect(() => {
    if (playerRef.current && chapter <= sources.length - 1) {
      playerRef.current.src(sources[chapter]);
      playerRef.current.play();
    } else {
      playerRef.current?.dispose();
    }
  }, [playerRef, sources, chapter]);

  return (
    <PlayerContext.Provider value={{showPlayer, setShowPlayer, playerRef, sources, chapter, addChapter, removeChapter, setSources, tracks, setTracks, chapterRef}}>
      {children}
    </PlayerContext.Provider>
  );
};

const usePlayerContext = () => useContext(PlayerContext);

export { PlayerProvider, usePlayerContext }
