import { Button } from 'react-bootstrap'
import styled from 'styled-components'

const AnimatedButton = styled(Button)`
  -webkit-animation: pulsate-bck 2s ease-in-out 2s infinite;
  animation: pulsate-bck 2s ease-in-out 2s infinite;

  @-webkit-keyframes pulsate-bck {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes pulsate-bck {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

`

export default function Buy({ url }) {
  return (
    <>
      <h1>Adquira agora mesmo a sua leitura da lua 100% personalizada</h1>
      <p>
        <AnimatedButton
          variant="warning"
          className='fw-bold text-primary'
          onClick={() => {
            window.location.href = url;
          }}
        >
          Eu quero minha leitura personalizada!
        </AnimatedButton>
      </p>
      <p><img src={`${process.env.REACT_APP_CDN_URL}/assets/images/animations/book.png`} alt="E-book" style={{ maxHeight: '30vh' }} /></p>
      <p>Mais de <span className='text-primary bg-warning'>12.415</span> PESSOAS COMPRAM SUA LEITURA PERSONALIZADA!</p>
      <p><img src={`${process.env.REACT_APP_CDN_URL}/assets/images/animations/card.png`} alt="Cartões aceitos" height={30} /></p>
      <p><img src={`${process.env.REACT_APP_CDN_URL}/assets/images/animations/safe.png`} alt="Sua compra está segura!" height={40} /></p>
    </>
  )
}