import React from 'react';
import { Navigate } from 'react-router-dom';
import { useStepsContext } from '../../contexts/Steps';
import isDev from '../../helpers/environment';

const ProtectedRoute = ({ element: Element, requirements, ...rest }) => {
  const { answers } = useStepsContext()

  const isAllowed = () => {
    for (let i = 0; i < requirements.length; i++) {
      if (!answers()[requirements[i]])
        return false;
    }

    return true;
  }

  return isAllowed() || isDev() ? <Element {...rest} /> : <Navigate to="/" replace={true} />;
};

export default ProtectedRoute;
