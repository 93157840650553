import styled from "styled-components";
import SignCard from "../elements/SignCard";
import StepTitle from "../elements/StepTitle";
import { useStepsContext } from "../../contexts/Steps";
import { useNavigate } from "react-router-dom";

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 520px;
  margin: 0 auto;
`

export default function DayPicker() {
  const { setDay } = useStepsContext()
  const navigate = useNavigate()

  const days = [...Array(31).keys()]

  const handleClick = day => {
    setDay(day)
    navigate("/year-month", { replace: true })
  }

  return (
    <div>
      <StepTitle back="/">
        Etapa 2: Selecione o dia de seu nascimento
      </StepTitle>
      <CardsContainer>
        {days.map(day => {
          const daySum = day + 1

          return <SignCard text={daySum} key={daySum} onClick={() => handleClick(daySum)} id={`day-${daySum}`}></SignCard>
        })}
      </CardsContainer>
    </div>
  );
}
