import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.scss';
import App from './App';
import * as Sentry from "@sentry/react";
import isDev from './helpers/environment';

const dsn = "https://e2cf7646914e103d159c3261e03726e9@o4504489106604032.ingest.us.sentry.io/4507809885519872";

if (isDev()) {
  Sentry.init({
    dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/api\.leituradalua\.com/],
    environment: 'development',
  });
} else {
  Sentry.init({
    dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/api\.leituradalua\.com/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: 'production',
  });
}



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
