import axios from "axios";

const URL = 'https://raw.githubusercontent.com/dr5hn/countries-states-cities-database/master'

const flagCdn = code => `https://flagcdn.com/16x12/${code}.png`

export const getCountries = async () => {
  const response = await axios.get(URL + '/countries.json');
  const data = await response.data;

  const countryOptions = [];

  data.forEach(country => {
    const option = {
      value: country.id,
      label: country.translations['pt-BR'] || country.name,
      image: flagCdn(country.iso2.toLowerCase()),
      code: country.iso2,
    };

    if (country.iso2 !== 'BR') {
      countryOptions.push(option);
    } else {
      countryOptions.unshift(option);
    }
  });

  return countryOptions;
};

export const getStates = async ({ queryKey }) => {
  const [, country] = queryKey

  if (country == null) return []

  const response = await axios.get(URL + '/states.json');
  const data = await response.data;

  const stateOptions = data
    .filter(state => state.country_id === country.value)
    .map(state => ({
        value: state.id,
        label: state.name,
        code: state.state_code,
    }));

  return stateOptions;
};

export const getCities = async ({ queryKey }) => {
  const [, state] = queryKey

  if (state == null) return []

  const response = await axios.get(URL + '/cities.json');
  const data = await response.data;

  const cityOptions = data
    .filter(city => city.state_id === state.value)
    .map(city => ({
      value: city.id,
      label: city.name,
      latitude: city.latitude,
      longitude: city.longitude,
    }));

  return cityOptions;
};