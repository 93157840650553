import { useEffect, useState } from "react";
import { usePlayerContext } from "../../contexts/Player";
import PlayButton from "./Animations/PlayButton";
import Moon from "./Animations/Moon";
import Signs from "./Animations/Signs";
import { createGlobalStyle } from "styled-components";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import Phases from "./Animations/Phases";
import Map from "./Animations/Map";
import Sign from "./Animations/Sign";
import { useStepsContext } from "../../contexts/Steps";
import YourSign from "./Animations/YourSign";
import CheckPhases from "./Animations/CheckPhases";
import YourPhase from "./Animations/YourPhase";
import Buy from "./Animations/Buy";
import PreloadImages from "./Animations/PreloadImages";

const GlobalStyles = createGlobalStyle`
  .fade-appear {
    opacity: 0;
    z-index: 1;
  }
  .fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 1000ms linear;
  }
  .fade-enter{
    opacity: 0;
  }
  .fade-exit{
    opacity: 1;
  }
  .fade-enter-active{
    opacity: 1;
  }
  .fade-exit-active{
    opacity: 0;
  }
  .fade-enter-active,
  .fade-exit-active{
    transition: opacity 500ms;
  }
`

export default function Result() {
  const { moonSign, moonPhase, checkoutUrl } = useStepsContext();
  const { showPlayer, setShowPlayer, addSource, playerRef, chapter } = usePlayerContext();
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true)
    playerRef.current.play()
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPlayer(true)
    }, 3000);

    return () => clearTimeout(timer);
  }, [setShowPlayer, addSource]);

  if (!showPlayer) return

  return(
    <>
      <GlobalStyles />
      <PreloadImages />
      <div className="container d-flex h-100">
      <div className="row justify-content-center align-self-center">
        <SwitchTransition mode="out-in">
        <CSSTransition
          key={
            isPlaying
              ? (chapter >= 10 ? 'book' : (chapter > 1 && (chapter < 8 || chapter === 10) ? chapter : 'moon'))
              : 'play'
          }
          timeout={1500}
          classNames="fade"
          appear={true}
        >
          {isPlaying ? (
            <>
              {chapter <= 1 ? (
                <Moon />
              ) : chapter === 2 ? (
                <Signs />
              ) : chapter === 3 ? (
                <Phases />
              ) : chapter === 4 ? (
                <Map />
              ) : chapter === 5 ? (
                <Sign sign={moonSign} />
              ) : chapter === 6 ? (
                <YourSign sign={moonSign} />
              ) : chapter === 7 ? (
                <CheckPhases />
              ) : chapter === 8 || chapter === 9 ? (
                <YourPhase phase={moonPhase} />
              ) : (
                <Buy url={checkoutUrl()} />
              )}
            </>
          ) : (
            <PlayButton onClick={handlePlay}/>
          )}
        </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
    </>
  )
}