import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { usePlayerContext } from './Player';

const BackgroundContext = createContext();

// Crie um provedor de contexto
const BackgroundProvider = ({ children }) => {
    const [backgroundMusic, setBackgroundMusic] = useState(null)
    const audioRef = useRef(null)
    const location = useLocation()

    useEffect(() => {
      audioRef.current.volume = 0.4

      if (backgroundMusic === 'play')
        audioRef.current.play()

      if (backgroundMusic === 'stop') {
        audioRef.current.pause()
        audioRef.current.currentTime = 0
      }
    }, [backgroundMusic])

    const play = () => {
      setBackgroundMusic('play')
    }

    const stop = () => {
      setBackgroundMusic('stop')
    }

    const isPlayingBackgroundMusic = () => backgroundMusic === 'play'

    const { setShowPlayer } = usePlayerContext();

    useEffect(() => {
      if (location.pathname === '/result') {
        document.body.style.color = '#ffffff'
        document.body.style.transition = 'color 3s ease-in'
        document.body.style.animation = 'image 3s 1 alternate forwards'
      } else {
        document.body.style = ""
        setShowPlayer(false)
      }
    }, [location, setShowPlayer])

    return (
      <BackgroundContext.Provider value={{ play, stop, isPlayingBackgroundMusic }}>
        {children}
        <audio src={process.env.REACT_APP_BACKGROUND_MUSIC} ref={audioRef} loop></audio>
      </BackgroundContext.Provider>
    );
};

const useBackgroundContext = () => useContext(BackgroundContext);

export { BackgroundProvider, useBackgroundContext }
