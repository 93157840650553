import StepTitle from "../elements/StepTitle";

export default function Terms() {
  return (
    <div>
      <StepTitle back="/">
        Política de Privacidade e Termos de Serviço
      </StepTitle>

      <p>Este é um acordo legal entre a pessoa que usa o LeituraDaLua.com ("você" ou "seu") e o proprietário e operadores do LeituraDaLua.com ("nós", "nos" ou "nosso"). Este acordo rege o seu uso do serviço LeituraDaLua.com ("o serviço"). Ao acessar materiais no site LeituraDaLua.com ("Site"), você concorda com estes Termos de Serviço ("Termos de Serviço") e nossa Política de Privacidade ("Política de Privacidade").</p>
      <p>Este documento está dividido em duas partes: Política de Privacidade e Termos de Serviço.</p>
      <ol>
        <h2>Política de Privacidade</h2>
        <li>Nós divulgamos informações potencialmente pessoais e identificáveis aos nossos funcionários, contratados e organizações afiliadas que precisam conhecer essas informações para processá-las em nosso nome ou para fornecer o serviço. Alguns de nossos funcionários, contratados e organizações afiliadas podem estar localizados fora do seu país de origem; ao usar o serviço, você consente com a transferência de informações pessoais para o Brasil outras jurisdições onde nós, nossos funcionários, contratados e organizações afiliadas estamos localizados.</li>
        <li>Quando você solicita uma Leitura da Lua, você é automaticamente inscrito em uma assinatura gratuita. Você pode cancelar a inscrição a qualquer momento clicando no link de cancelamento na parte inferior de qualquer um de nossos e-mails, ou enviar um e-mail para contato@leituradalua.com e solicitar a exclusão de sua conta.</li>
        <li>Podemos fornecer informações sobre sua conta e seu uso do serviço a terceiros, a nosso exclusivo critério, se:</li>
        <ol>
          <li>for solicitado por autoridades policiais.</li>
          <li>tivermos motivos para acreditar que é do seu interesse fornecer tais informações.</li>
          <li>for necessário para manter o serviço em funcionamento ou para diagnosticar ou corrigir problemas com o serviço ou outra rede.</li>
          <li>precisarmos investigar e/ou acompanhar solicitações de suporte para produtos e serviços.</li>
        </ol>
        <li>Como a maioria dos operadores de sites, coletamos informações não identificáveis pessoalmente que navegadores e servidores geralmente disponibilizam (como o tipo de navegador, preferência de idioma, site de referência e a data e hora de cada solicitação do visitante). Nosso objetivo em coletar informações não identificáveis pessoalmente é entender melhor como os visitantes usam o Site. De tempos em tempos, podemos divulgar informações não identificáveis pessoalmente de forma agregada, por exemplo, publicando um relatório sobre tendências no uso do serviço.</li>
        <li>Certos usuários do serviço optam por interagir com o serviço de uma maneira que pode exigir a coleta de informações pessoalmente identificáveis que podem ser usadas para contatá-lo ou identificá-lo ("Dados Pessoais"). Por exemplo, solicitamos que visitantes que se inscrevem para informações adicionais sobre sua leitura forneçam informações pessoalmente identificáveis que podem incluir, mas não estão limitadas a: nome, endereço de e-mail, data de nascimento, hora de nascimento, local de nascimento, gênero, estado civil ou outras informações. Aqueles que realizam transações com o serviço – comprando uma assinatura, por exemplo – são solicitados a fornecer informações adicionais, incluindo, conforme necessário, as informações pessoais e financeiras necessárias para processar essas transações. Os visitantes podem sempre se recusar a fornecer informações pessoalmente identificáveis, com a ressalva de que isso pode impedi-los de participar de certas atividades que fazem parte do serviço.</li>
        <li>Podemos usar seus Dados Pessoais para contatá-lo com newsletters, materiais de marketing ou promocionais e outras informações que possam ser de seu interesse. Você pode optar por não receber qualquer um ou todos esses comunicados de nós seguindo o link de cancelamento fornecido em qualquer e-mail que enviamos.</li>
        <li>Também rastreamos muitas das ações que você realiza no site para melhorar o serviço.</li>
        <li>Quando você se inscreve no Leitura da Lua, enviaremos e-mails de LeituraDaLua.com.</li>
        <ol>
          <li>Alguns dos e-mails que enviamos oferecem produtos ou serviços que acreditamos que possam ser de seu interesse, como Tarô, Astrologia, Numerologia, Anjos, Runas e outros tópicos. Essas ofertas podem ser de terceiros.</li>
          <li>Você pode facilmente optar por não receber qualquer um desses e-mails clicando no link de cancelamento na parte inferior de cada e-mail.</li>
          <li>Você deve cancelar a inscrição do LeituraDaLua.com.</li>
        </ol>
        <li>Um cookie é uma sequência de informações que um site armazena no computador de um visitante, e que o navegador do visitante fornece ao site cada vez que o visitante retorna. Usamos cookies para nos ajudar a identificar e rastrear visitantes, seu uso do serviço e suas preferências de acesso ao Site. Visitantes que não desejam ter cookies colocados em seus computadores devem configurar seus navegadores para recusar cookies antes de usar o serviço, com a desvantagem de que certos recursos do serviço podem não funcionar corretamente sem o auxílio de cookies.</li>
        <li>Se o serviço, ou substancialmente todos os seus ativos, fossem adquiridos, ou se deixasse de operar ou entrasse em falência, as informações do usuário seriam um dos ativos transferidos ou adquiridos por um terceiro. Você reconhece que tais transferências podem ocorrer e que qualquer adquirente do serviço pode continuar a usar suas informações pessoais conforme estabelecido nesta política.</li>
        <li>Anúncios exibidos no serviço podem ser entregues aos usuários por parceiros de publicidade, que podem definir cookies. Esses cookies permitem que o servidor de anúncios reconheça seu computador cada vez que lhe envia uma publicidade online para compilar informações sobre você ou outros que usam seu computador. Essas informações permitem que as redes de anúncios, entre outras coisas, entreguem anúncios direcionados que acreditam ser de maior interesse para você. Esta Política de Privacidade abrange o uso de cookies pelo serviço e não abrange o uso de cookies por quaisquer anunciantes.</li>
        <li>Ao transmitir informações para o serviço e o Site, você nos concede um direito e licença perpétuos, mundiais, livres de royalties, irrevogáveis e não exclusivos (com direitos de sublicença) para usar, reproduzir, modificar, adaptar, publicar, traduzir, editar e distribuir as informações (no todo ou em parte) mundialmente e/ou incorporá-las em outras obras em qualquer forma, mídia ou tecnologia agora conhecida ou desenvolvida no futuro pelo prazo completo de qualquer direito autoral que possa existir em tal submissão.</li>
        <li>O LeituraDaLua.com está baseado no Brasil, e seus dados podem ser processados no Brasil e em outros países ou jurisdições.</li>
        <li>Armazenamos informações pessoais em servidores seguros que são gerenciados por nós e nossos provedores de serviços, e backups de todos os dados do site (incluindo suas informações pessoais) são armazenados em serviços de terceiros.</li>
        <li>Mantemos salvaguardas razoáveis para ajudar a proteger os dados que coletamos.</li>
        <li>Para instruções sobre como você pode acessar os Dados Pessoais que nos forneceu ou como alterar tais informações, envie um e-mail para contato@leituradalua.com</li>
        <br />
        <h2>Termos de Serviço</h2>
        <h3>Apenas para Fins de Entretenimento</h3>
        <li>Acreditamos que sua Leitura da Lua e Astrologia são uma maneira maravilhosa de dar um passo atrás e tentar avaliar uma situação de uma perspectiva única, mas a Astrologia não é uma ciência exata e recomendamos que você não leve as interpretações muito literalmente. <b>O serviço é fornecido apenas para fins de entretenimento</b>. Por favor, use seu melhor julgamento e bom senso. As informações fornecidas pelo serviço não devem ser usadas no lugar de quaisquer recomendações de profissionais legais, médicos ou financeiros ou outros conselheiros profissionais.</li>
        <h3>Recusa de Serviço</h3>
        <li>Reservamo-nos o direito de recusar o serviço a qualquer pessoa por qualquer motivo.</li>
        <li>O serviço deve ser usado apenas por indivíduos com mais de 18 anos. Residentes de qualquer jurisdição que possa proibir o serviço não devem acessá-lo. É sua responsabilidade garantir que você é elegível para usar o serviço.</li>
        <h3>Contas e Assinaturas</h3>
        <li>Se você ama sua leitura gratuita tanto quanto esperamos, daremos a você a oportunidade de se tornar um membro pago. Existem dois níveis de assinatura: gratuita e paga. Membros pagos têm acesso a material adicional.</li>
        <li>Quando você solicita informações adicionais do serviço e fornece seu endereço de e-mail, você automaticamente se torna inscrito em uma assinatura gratuita.</li>
        <li>Enquanto você estiver inscrito em qualquer assinatura, podemos enviar e-mails sobre o serviço ou sua conta a qualquer momento.</li>
        <li>Você pode cancelar a inscrição e/ou cancelar sua assinatura a qualquer momento.</li>
        <li>Você é responsável por qualquer uso de sua conta e assinatura; consequentemente, você é responsável por manter sua senha de conta e os links de acesso por e-mail que você usa para acessar o serviço seguros.</li>
        <h3>Links de Terceiros</h3>
        <li>Às vezes fornecemos referências e links para outros sites da World Wide Web em e-mails e no Site. Esses links não devem ser considerados um endosso, aprovação ou acordo com qualquer informação ou recurso oferecido em sites que você pode acessar através do nosso site. Não somos responsáveis pelo conteúdo ou práticas de sites de terceiros.</li>
        <li>Você deve assumir que podemos ter um relacionamento de afiliado e/ou outra conexão material com os fornecedores de bens e serviços mencionados neste site e podemos ser compensados quando você compra de um fornecedor. Você deve sempre realizar a devida diligência antes de comprar bens ou serviços de qualquer pessoa na Internet ou offline.</li>
        <h3>Jurisdição</h3>
        <li>Qualquer pessoa que use nosso site precisará cumprir as leis locais do Brasil. Isso se aplica particularmente a usuários fora do Brasil. Estes Termos e Condições são regidos pelas leis do Brasil e se aplicam a quaisquer acordos sob esta jurisdição. Ao usar nosso site, você concorda em estar vinculado por estes Termos e concorda em seguir a jurisdição dos tribunais estaduais e federais no Brasil. Se surgir uma divergência sob estes Termos, primeiro tentaremos chegar a uma resolução usando um mediador aceitável para ambas as partes e localizado no Brasil. Os custos de mediação fora das taxas de advogado serão divididos igualmente.</li>
        <h3>Não é Conselho Profissional</h3>
        <li>As informações fornecidas através do serviço, no Site e em todos os materiais que enviamos a você são apenas para fins de entretenimento. As informações do serviço não se destinam a ser um substituto para aconselhamento profissional. Não devem ser usadas como aconselhamento médico profissional, diagnóstico ou tratamento. Não devem ser usadas como aconselhamento financeiro ou de investimento profissional. Não devem ser usadas como aconselhamento jurídico profissional. Nunca atrase ou desconsidere a busca de aconselhamento profissional por causa de algo que você leu no Site ou no serviço.<br /><br /><b>Qualquer e todas as decisões que você tomar com base nas informações fornecidas por nós ou pelo serviço serão de sua única e exclusiva responsabilidade.</b></li>
        <h3>Estudos de Caso, Testemunhos e Exemplos</h3>
        <li>Os testemunhos, estudos de caso, declarações, opiniões e exemplos encontrados no Site ou em e-mails refletem as experiências dos indivíduos que os escreveram. Eles não se destinam a representar ou garantir que todos alcançarão os mesmos ou semelhantes resultados. Sempre realize sua própria devida diligência ao tomar uma decisão e não tome esses resultados pelo valor nominal. Não somos responsáveis por qualquer omissão ou erro nos estudos de caso, testemunhos, exemplos ou informações de resultados típicos fornecidos a nós por clientes, fabricantes ou terceiros respeitáveis.</li>
        <li>Nenhum dos indivíduos cujos testemunhos, revisões ou feedback aparecem neste site recebeu qualquer forma de pagamento, compensação ou comissão de afiliado de qualquer produto ou serviço que tenham revisado, a menos que declarado claramente de outra forma.</li>
        <li>Todos os testemunhos encontrados no Site refletem as opiniões subjetivas dos indivíduos apresentados e obtivemos sua permissão para compartilhá-los. Confiamos, ao melhor de nosso conhecimento, que esses testemunhos representam as opiniões honestas dos colaboradores. Os testemunhos são fornecidos em sua forma mais original, exceto por correções gramaticais. Alguns testemunhos podem ter sido editados para clareza ou encurtados em comprimento. Essas edições foram feitas com o maior cuidado para preservar a integridade do testemunho original. Para proteger a identidade dos clientes que fornecem informações, os nomes declarados podem ser pseudônimos, locais podem ser alterados e quaisquer fotos ou vídeos podem ser reencenações por atores, atores de voz ou obtidos de outras fontes.</li>
        <h3>Limitação de Responsabilidade</h3>
        <li>O uso do serviço é por sua conta e risco.</li>
        <li>O conteúdo no Site e entregue através de e-mails é fornecido aos nossos visitantes apenas para fins de entretenimento. Não fazemos reivindicações de que nossas leituras devem ser usadas para qualquer propósito além de entretenimento e desencorajamos fortemente o uso dessas leituras por qualquer razão que não seja diversão.<br /><br />O SERVIÇO É FORNECIDO "COMO ESTÁ" E QUAISQUER GARANTIAS EXPRESSAS OU IMPLÍCITAS, INCLUINDO, MAS NÃO LIMITADAS A, AS GARANTIAS IMPLÍCITAS DE COMERCIALIZAÇÃO E ADEQUAÇÃO A UM PROPÓSITO ESPECÍFICO SÃO RENUNCIADAS. EM NENHUM CASO SEREMOS RESPONSÁVEIS POR QUAISQUER DANOS DIRETOS, INDIRETOS, INCIDENTAIS, ESPECIAIS, EXEMPLARES OU CONSEQUENCIAIS (INCLUINDO, MAS NÃO LIMITADOS A, AQUISIÇÃO DE BENS OU SERVIÇOS SUBSTITUTOS; PERDA DE USO, DADOS OU LUCROS; OU INTERRUPÇÃO DE NEGÓCIOS) NO ENTANTO CAUSADOS E EM QUALQUER TEORIA DE RESPONSABILIDADE, SEJA EM CONTRATO, RESPONSABILIDADE ESTRITA OU DELITO (INCLUINDO NEGLIGÊNCIA OU OUTRO) DECORRENTE DE QUALQUER FORMA DO USO DESTE SERVIÇO, MESMO SE AVISADO DA POSSIBILIDADE DE TAIS DANOS.</li>
        <h3>Miscelânea</h3>
        <li>Se qualquer disposição destes Termos de Serviço for considerada nula, ilegal ou inexequível por qualquer razão, essa disposição será separada do restante dos termos e condições e não afetará a validade e exequibilidade de quaisquer das disposições remanescentes.</li>
        <li>Podemos atualizar os Termos de Serviço a qualquer momento, mas você não estará vinculado por quaisquer atualizações até 30 dias após as alterações serem publicadas. Por favor, verifique regularmente. Seu uso continuado do serviço após alterações nos termos de serviço constitui sua aceitação das emendas.<br /><br />Se você tiver quaisquer perguntas, comentários ou preocupações, entre em contato conosco em contato@leituradalua.com.<br /><br /><i>Última atualização em 8 de março de 2024</i></li>
      </ol>
    </div>
  )
}