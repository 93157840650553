import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faMusic, faShoppingCart, faUser, faUserSlash, faVolumeXmark } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { usePlayerContext } from '../../contexts/Player';
import { ButtonGroup, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useBackgroundContext } from '../../contexts/Background';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStepsContext } from '../../contexts/Steps';

const Logo = styled.h1`
  font-weight: 300;
  cursor: default;
  margin-bottom: 0.3em;
`

const Controls = styled(ButtonGroup)`
  margin-bottom: 0.67em;
`

function Header() {
  const location = useLocation();
  const { showPlayer, playerRef } = usePlayerContext();
  const { play, stop, isPlayingBackgroundMusic } = useBackgroundContext();
  const { checkoutUrl } = useStepsContext();
  const [ playerMuted, setPlayerMuted ] = useState(false);

  const handleMusic = () => {
    if (isPlayingBackgroundMusic()) {
      stop()
    } else {
      play()
    }
  }

  const handlePlayer = () => {
    if (playerMuted) {
      playerRef.current.muted(false)
      setPlayerMuted(false)
    } else {
      playerRef.current.muted(true)
      setPlayerMuted(true)
    }
  }

  return (
    <header>
      <Logo>
        <FontAwesomeIcon icon={faMoon} /> Leitura da Lua
      </Logo>
      <Controls>
        {location.pathname !== '/result' && (
          <Button variant='outline-primary' size="sm" type="button" onClick={handleMusic}>
            {isPlayingBackgroundMusic() ? <FontAwesomeIcon icon={faVolumeXmark} /> : <FontAwesomeIcon icon={faMusic} />}
          </Button>
        )}
        {showPlayer && (
          <>
            <Button variant='outline-light' size="sm" type="button" onClick={handleMusic}>
              {isPlayingBackgroundMusic() ? <FontAwesomeIcon icon={faVolumeXmark} /> : <FontAwesomeIcon icon={faMusic} />}
            </Button>
            <Button variant="outline-light" size="sm" type="button" onClick={handlePlayer}>
              {playerMuted ? <FontAwesomeIcon icon={faUser} /> : <FontAwesomeIcon icon={faUserSlash} />}
            </Button>
            <OverlayTrigger placement='bottom' overlay={<Tooltip>Comprar minha leitura personalizada!</Tooltip>}>
              <Button variant="outline-light" size="sm" type="button">
                <FontAwesomeIcon icon={faShoppingCart} onClick={() => window.open(checkoutUrl(), '_blank') } />
              </Button>
            </OverlayTrigger>
          </>
        )}
      </Controls>
    </header>
  );
}

export default Header;
