import Header from "../modules/Header";
import Main from "../modules/Main";

export default function Error() {
  return (
    <>
      <Header />
      <Main>
        <div>
          <h1>Opa! Tivemos um imprevisto.</h1>
          <p>Desculpe, não conseguimos processar sua solicitação.</p>
        </div>
      </Main>
    </>
  );
}