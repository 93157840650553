import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from "./components/routes/ProtectedRoute.js";
import { PlayerProvider } from './contexts/Player.js';
import { createGlobalStyle } from "styled-components";

import Default from "./components/layouts/Default.js";
import Error from "./components/layouts/Error.js";
import SignPicker from "./components/sections/SignPicker.js";
import DayPicker from "./components/sections/DayPicker.js";
import YearMonthPicker from "./components/sections/YearMonthPicker";
import TimeQuestion from "./components/sections/TimeQuestion.js";
import TimeAnswer from "./components/sections/TimeAnswer.js";
import Calculating from "./components/sections/Calculating.js";
import Location from "./components/sections/Location.js";
import Name from "./components/sections/Name.js";
import Result from "./components/sections/Result.js";
import Terms from "./components/sections/Terms.js";

import { StepsProvider } from "./contexts/Steps.js";
import { BackgroundProvider } from "./contexts/Background.js";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: Infinity,
    },
  },
})

const GlobalStyles = createGlobalStyle`
  body {
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    margin: 0;
    background: linear-gradient(to right, #e0e6f4, #d3cce3, #e9e4f0);
    background-image: url('${process.env.REACT_APP_CDN_URL}/assets/images/background.png');
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  @keyframes image {
    0% {
      background-image: url('${process.env.REACT_APP_CDN_URL}/assets/images/background.png');
      background-color: #d3cce3;
    }

    100% {
      background-image: url('${process.env.REACT_APP_CDN_URL}/assets/images/result-background.jpg');
      background-color: #d3cce3;
    }
  }
`

function App() {
  const img = new Image();
  img.src = `${process.env.REACT_APP_CDN_URL}/assets/images/result-background.jpg`;

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyles />
      <StepsProvider>
        <PlayerProvider>
          <Router>
            <BackgroundProvider>
              <Routes>
                <Route path="/" element={<Default />}>
                  <Route index element={<SignPicker />} />
                  <Route path="/terms" element={<ProtectedRoute requirements={[]} element={Terms} />} />
                  <Route path="/day" element={<ProtectedRoute requirements={['sign']} element={DayPicker} />} />
                  <Route path="/year-month" element={<ProtectedRoute requirements={['sign', 'day']} element={YearMonthPicker} />} />
                  <Route path="/time" element={<ProtectedRoute requirements={['sign', 'day', 'year', 'month']} element={TimeQuestion} />} />
                  <Route path="/birth-time" element={<ProtectedRoute requirements={['sign', 'day', 'year', 'month']} element={TimeAnswer} />} />
                  <Route path="/location" element={<ProtectedRoute requirements={['sign', 'day', 'year', 'month']} element={Location} />} />
                  <Route path="/name" element={<ProtectedRoute requirements={['sign', 'day', 'year', 'month', 'country', 'state', 'city']} element={Name} />} />
                  <Route path="/calculating" element={<ProtectedRoute requirements={['sign', 'day', 'year', 'month', 'country', 'state', 'city', 'name']} element={Calculating} />} />
                  <Route path="/result" element={<ProtectedRoute requirements={['sign', 'day', 'year', 'month', 'country', 'state', 'city', 'name']} element={Result} />} />
                </Route>
                <Route path="*" element={<Error />} />
              </Routes>
            </BackgroundProvider>
          </Router>
        </PlayerProvider>
      </StepsProvider>
    </QueryClientProvider>
  );
}

export default App;
