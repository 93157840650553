import axios from "axios";

const URL = process.env.REACT_APP_MOON_API;

export const getResults = async ({ queryKey }) => {
  const [, year, month, day, hours, minutes, lng, lat, country] = queryKey

  const response = await axios.post(URL, {
    year: year,
    month: month,
    day: day,
    hours: hours,
    minutes: minutes,
    lng: parseFloat(lng),
    lat: parseFloat(lat),
    country: country
  }, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  });

  const data = await response.data;

  return data;
};