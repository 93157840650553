import StepTitle from "../elements/StepTitle";
import TimeInput from "../elements/TimeInput";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStepsContext } from "../../contexts/Steps";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bounce, toast, ToastContainer } from 'react-toastify';
import Button from 'react-bootstrap/Button';

export default function TimeAnswer() {
  const { setHour, setMinute } = useStepsContext()
  const [error, setError] = useState(false)
  const navigate = useNavigate()
  const timeRef = useRef()

  const handleClick = () => {
    if (isValidTime()) {
      const [hour, minute] = timeRef.current.value.split(":")

      setHour(parseInt(hour))
      setMinute(parseInt(minute))

      navigate("/location", { replace: true })
    } else {
      setError(true)
      timeRef.current?.focus()

      toast.error('Por favor, insira um horário válido.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      })
    }
  }

  const isValidTime = () => {
    return /^([01]\d|2[0-3]):([0-5]\d)$/.test(timeRef.current.value);
  }

  const handleInput = () => {
    setError(false)
  }

  return (
    <div>
      <StepTitle back="/time">
        Etapa 5: Qual é a hora de seu nascimento?
      </StepTitle>
      <TimeInput ref={timeRef} error={error} onInput={handleInput} />
      <Button onClick={handleClick} id="time-continue">Continuar <FontAwesomeIcon icon={faArrowRight} /></Button>
      <ToastContainer />
    </div>
  );
}
