import Video from "../elements/Video";
import isDev from "../../helpers/environment";
import { usePlayerContext } from "../../contexts/Player";
import styled from "styled-components";
import { findSubtitleAtTime } from "../../services/vttsParse";
import { useRef } from "react";
import { useStepsContext } from "../../contexts/Steps";
import { Link } from "react-router-dom";

const Captions = styled.p`
  font-weight: bold;
  text-shadow: 2px 2px 0 black;
  padding: 5px 10px;
  cursor: default;
`

const Copy = styled.p`
  font-size: 12px;
  padding: 5px;
  margin: 0;
  background-color: white;
`

function Footer() {
  const captionsRef = useRef(null);
  const { showPlayer, playerRef, sources, addChapter, chapterRef, tracks } = usePlayerContext();
  const { name } = useStepsContext();
  const year = new Date().getFullYear();

  const controlBarChildren = [
    'muteToggle',
  ]

  if (isDev())
    controlBarChildren.push('progressControl')

  const videoJsOptions = {
    autoplay: false,
    audioOnlyMode: true,
    controls: isDev(),
    responsive: true,
    fluid: true,
    sources: sources[chapterRef.current],
    loadingSpinner: false,
    bigPlayButton: false,
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on('ended', () => {
      addChapter()
    });

    player.on('timeupdate', () => {
      const currentTime = playerRef.current.currentTime();
      const captionText = findSubtitleAtTime(tracks[chapterRef.current], currentTime)?.text;

      if (captionsRef.current.innerHTML !== captionText)
        captionsRef.current.innerHTML = captionText ? captionText.replace("{name}", name || '') : ''
    })
  };

  return (
    <footer>
      {showPlayer ? (
        <>
          <Captions ref={captionsRef}>Clique na lua para começar!</Captions>
          <Video options={videoJsOptions} onReady={handlePlayerReady} />
        </>
      ) : (
        <Copy>&copy; Leitura da Lua {year}. Todos os direitos reservados. | <Link to='/terms'>Termos de Uso e Política de Privacidade</Link></Copy>
      )}
    </footer>
  );
}

export default Footer;
