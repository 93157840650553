import styled from "styled-components";
import { Link } from "react-router-dom";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Title = styled.h3`
  font-weight: bold;
`

const Back = styled.span`
  a {
    color: #FFFFFF;
  }

  margin-right: 10px;
`

export default function StepTitle({children, back}) {
  return (
    <div>
      <Title>
        {back &&
          <Back>
            <Link to={back} replace={true}><FontAwesomeIcon icon={faCircleArrowLeft} /></Link>
          </Back>
        }
        {children}
      </Title>
    </div>
  );
}
