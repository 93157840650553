const moonPhaseImages = {
    'New Moon': 'new-moon',
    'Waxing Crescent': 'waxing-crescent',
    'First Quarter': 'first-quarter',
    'Waxing Gibbous': 'waxing-gibbous',
    'Full Moon': 'full-moon',
    'Waning Gibbous': 'waning-gibbous',
    'Last Quarter': 'last-quarter',
    'Waning Crescent': 'waning-crescent',
};

export default function YourPhase(props) {
    const { phase, ...otherProps } = props

    return (
        <img src={`${process.env.REACT_APP_CDN_URL}/assets/images/animations/moon-phases/${moonPhaseImages[props.phase]}.png`} alt="Sua Lua" style={{ maxWidth: '400px' }} {...otherProps} />
    );
}
