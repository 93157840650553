import styled from "styled-components";
import StepTitle from "../elements/StepTitle";
import { useStepsContext } from "../../contexts/Steps";
import SignCard from "../elements/SignCard";
import { useRef, useState } from "react";
import { Bounce, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0;
  gap: 25px;
`

export default function YearMonthPicker() {
  const { sign, year, setMonth, setYear } = useStepsContext()
  const [error, setError] = useState(false)
  const navigate = useNavigate()
  const yearRef = useRef()

  const months = () => {
    const months = [
      { month: "Janeiro", month_value: "1", signs: ["capricorn", "aquarius"] },
      { month: "Fevereiro", month_value: "2", signs: ["aquarius", "pisces"] },
      { month: "Março", month_value: "3", signs: ["pisces", "aries"] },
      { month: "Abril", month_value: "4", signs: ["aries", "taurus"] },
      { month: "Maio", month_value: "5", signs: ["taurus", "gemini"] },
      { month: "Junho", month_value: "6", signs: ["gemini", "cancer"] },
      { month: "Julho", month_value: "7", signs: ["cancer", "leo"] },
      { month: "Agosto", month_value: "8", signs: ["leo", "virgo"] },
      { month: "Setembro", month_value: "9", signs: ["virgo", "libra"] },
      { month: "Outubro", month_value: "10", signs: ["libra", "scorpio"] },
      { month: "Novembro", month_value: "11", signs: ["scorpio", "sagittarius"] },
      { month: "Dezembro", month_value: "12", signs: ["sagittarius", "capricorn"] }
    ].filter(entry => entry.signs.includes(sign || 'aquarius')).map(entry => ({ text: entry.month, value: entry.month_value }));

    return months;
  };

  const isValidYear = () => {
    const year = parseInt(yearRef.current.value)
    const currentYear = new Date().getFullYear()

    return year >= 1900 && year <= currentYear
  }

  const handleClick = month => {
    if (isValidYear()) {
      setMonth(parseInt(month))
      setYear(parseInt(yearRef.current.value))
      navigate("/time", { replace: true })
    } else {
      setError(true)
      yearRef.current.focus()

      toast.error('Por favor, insira um ano de nascimento válido.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      })
    }
  }

  const handleInput = () => {
    yearRef.current.value = yearRef.current.value.replace(/[^0-9]/g, '')
    setError(false)
  }

  const [firstMonth, secondMonth] = months()

  return (
    <div>
      <StepTitle back="/day">
        Etapa 3: Qual é o ano e mês de seu nascimento?
      </StepTitle>
      <Form.Control
        type="text"
        placeholder="1990"
        ref={yearRef}
        maxLength={4}
        onInput={handleInput}
        defaultValue={year}
        inputMode="numeric"
        pattern="[0-9]*"
        isInvalid={error}
        className="text-center fs-2 mb-3"
        id="year-input"
      />
      <Container>
        <SignCard
          style={{textAlign: 'left'}}
          text={firstMonth.text}
          width={'100%'}
          onClick={() => handleClick(firstMonth.value)}
          id={`month-${firstMonth.value}`}
        />
        <SignCard
          style={{textAlign: 'right'}}
          text={secondMonth.text}
          width={'100%'}
          onClick={() => handleClick(secondMonth.value)}
          id={`month-${firstMonth.value}`}
        />
      </Container>
      <ToastContainer />
    </div>
  );
}
