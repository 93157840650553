import axios from "axios";

const URL = process.env.REACT_APP_AUDIO_API + '/leitura-da-lua/';

export const getWelcome = async ({ queryKey }) => {
  const [, name] = queryKey
  const response = await axios.get(URL + 'welcome?name=' + name);
  const data = await response.data;

  return data.data;
};