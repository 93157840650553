import React, { createContext, useContext, useState } from 'react';
import isDev from '../helpers/environment';

const StepsContext = createContext();

const StepsProvider = ({ children }) => {
    const [sign, setSign] = useState(isDev() ? 'aries' : null)
    const [day, setDay] = useState(isDev() ? '5' : null)
    const [month, setMonth] = useState(isDev() ? '4' : null)
    const [year, setYear] = useState(isDev() ? '1995' : null)
    const [hour, setHour] = useState(12)
    const [minute, setMinute] = useState(0)
    const [name, setName] = useState(isDev() ? 'José' : null)
    const [country, setCountry] = useState(isDev() ? {
      "value": 31,
      "label": "Brasil",
      "image": "https://flagcdn.com/16x12/br.png",
      "code": "BR"
    } : null)
    const [state, setState] = useState(null)
    const [city, setCity] = useState(isDev() ? {
      "value": 10078,
      "label": "Acrelândia",
      "latitude": "-9.98045000",
      "longitude": "-66.84388000"
    } : null)
    const [moonSign, setMoonSign] = useState(null)
    const [moonPhase, setMoonPhase] = useState(null)

    const translatedPhases = {
      'New Moon': 'Lua Nova',
      'Waxing Crescent': 'Lua Crescente',
      'First Quarter': 'Lua Quarto Crescente',
      'Waxing Gibbous': 'Lua Gibosa Crescente',
      'Full Moon': 'Lua Cheia',
      'Waning Gibbous': 'Lua Gibosa Minguante',
      'Last Quarter': 'Lua Quarto Minguante',
      'Waning Crescent': 'Lua Minguante',
    };

    const checkout = {
      aries: 'https://go.perfectpay.com.br/PPU38CORM7A',
      taurus: 'https://go.perfectpay.com.br/PPU38CORM7B',
      gemini: 'https://go.perfectpay.com.br/PPU38CORM7C',
      cancer: 'https://go.perfectpay.com.br/PPU38CORM7D',
      leo: 'https://go.perfectpay.com.br/PPU38CORM7E',
      virgo: 'https://go.perfectpay.com.br/PPU38CORM7M',
      libra: 'https://go.perfectpay.com.br/PPU38CORM7N',
      scorpio: 'https://go.perfectpay.com.br/PPU38CORM7P',
      sagittarius: 'https://go.perfectpay.com.br/PPU38CORM7S',
      capricorn: 'https://go.perfectpay.com.br/PPU38CORM8P',
      aquarius: 'https://go.perfectpay.com.br/PPU38CORM8Q',
      pisces: 'https://go.perfectpay.com.br/PPU38CORM8R',
    }

    const checkoutUrl = () => {
      const url = checkout[sign?.toLowerCase()] || 'https://go.perfectpay.com.br/PPU38CORM7A'

      return url + '?src=' + encodeURI(translatedPhases[moonPhase]);
    }

    const answers = () => {
      return {
        sign,
        day,
        month,
        year,
        hour,
        minute,
        name,
        country,
        state,
        city,
        moonSign,
        moonPhase,
      }
    }

    const values = {
      sign,
      setSign,
      day,
      setDay,
      month,
      setMonth,
      year,
      setYear,
      hour,
      setHour,
      minute,
      setMinute,
      name,
      setName,
      country,
      setCountry,
      state,
      setState,
      city,
      setCity,
      moonSign,
      setMoonSign,
      moonPhase,
      setMoonPhase,
      answers,
      checkoutUrl
    }

    return (
      <StepsContext.Provider value={values}>
        {children}
      </StepsContext.Provider>
    );
};

const useStepsContext = () => useContext(StepsContext);

export { StepsProvider, useStepsContext }
