import ReactTextTransition from "react-text-transition";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Moon from "../elements/Moon";
import { useQuery } from "@tanstack/react-query";
import { getWelcome } from "../../services/audioApi";
import { useStepsContext } from "../../contexts/Steps";
import { usePlayerContext } from "../../contexts/Player";
import { getResults } from "../../services/moonApi";
import { toKebabCase } from 'js-convert-case';
import { Bounce, toast, ToastContainer } from "react-toastify";
import isDev from "../../helpers/environment";
import { convertVTTsToJSON } from "../../services/vttsParse";

function Calculating() {
  const URL = process.env.REACT_APP_CDN_URL;

  const { name, year, month, day, hour, minute, city, country, setMoonSign, setMoonPhase } = useStepsContext()
  const { setSources, setTracks } = usePlayerContext()
  const [LoadingIndex, setLoadingIndex] = useState(0);
  const navigate = useNavigate();
  const intervalTime = isDev() ? 0 : 4000

  const {
    data: welcomeData,
    isLoading: isWelcomeLoading,
    isSuccess: isWelcomeSuccess,
    isError: isWelcomeError,
  } = useQuery({ queryKey: ['welcome', name], queryFn: getWelcome })

  const {
    data: moonData,
    isLoading: isMoonLoading,
    isSuccess: isMoonSuccess,
    isError: isMoonError,
  } = useQuery({ queryKey: [
    'moon',
    year,
    month,
    day,
    hour,
    minute,
    city?.longitude,
    city?.latitude,
    country?.code,
  ], queryFn: getResults, enabled: !!(isWelcomeSuccess && welcomeData) })

  const { data: vttData, isLoading: isVttLoading, error: isVttError } = useQuery({
    queryKey: ['captions'],
    queryFn: () => convertVTTsToJSON([
      URL + '/generated.txt',
      URL + '/1-moon.txt',
      URL + '/2-signs.txt',
      URL + '/3-phases.txt',
      URL + '/4-map.txt',
      URL + `/5-moon-sign/${toKebabCase(moonData?.sign)}.txt`,
      URL + `/6-sign/${toKebabCase(moonData?.sign)}.txt`,
      URL + '/7-phase.txt',
      URL + `/8-your-phase/${toKebabCase(moonData?.phase)}.txt`,
      URL + '/9-your-phase.txt',
      URL + '/10-book.txt',
      URL + '/11-book.txt',
      URL + '/12-book.txt',
      URL + '/13-book.txt',
    ]),
    enabled: !!(isWelcomeSuccess && welcomeData && isMoonSuccess && moonData)
});

  const phrases = useMemo(() => [
    "Identificando sua personalidade",
    "Calculando possíveis relacionamentos",
    "Reconhecendo seu propósito na vida",
    "Personalizando a sua leitura da lua",
  ], [])

  useEffect(() => {
    if (isWelcomeError || isMoonError || isVttError) {
      if (isWelcomeError) console.error('Não foi possível carregar audio de boas-vindas')
      if (isMoonError) console.error('Não foi possível calcular a leitura da lua')
      if (isVttError) console.error('Não foi possível carregar as legendas dos audios')

      toast.error('Não foi possível realizar sua leitura da lua. Por favor, tente novamente', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          navigate('/', { replace: true })
        },
      })
      return;
    } else if (LoadingIndex >= phrases.length - 1 && !isWelcomeLoading && !isMoonLoading && !isVttLoading) {
      navigate('/result', { replace: true })
      return;
    }

    let interval = setInterval(() => {
      setLoadingIndex(LoadingIndex + 1);
    }, intervalTime);

    return () => {
      clearInterval(interval);
    };
  }, [LoadingIndex, phrases, navigate, isWelcomeLoading, isMoonLoading, isWelcomeError, isMoonError, intervalTime, isVttError, isVttLoading]);

  useEffect(() => {
    if (moonData && vttData && welcomeData) {
      setMoonSign(moonData?.sign)
      setMoonPhase(moonData?.phase)

      setSources([
        {
          src: welcomeData,
          type: 'audio/mp3'
        },
        {
          src: URL + '/1-moon.mp3',
          type: 'audio/mp3'
        },
        {
          src: URL + '/2-signs.mp3',
          type: 'audio/mp3'
        },
        {
          src: URL + '/3-phases.mp3',
          type: 'audio/mp3'
        },
        {
          src: URL + '/4-map.mp3',
          type: 'audio/mp3'
        },
        {
          src: URL + `/5-moon-sign/${toKebabCase(moonData?.sign)}.mp3`,
          type: 'audio/mp3'
        },
        {
          src: URL + `/6-sign/${toKebabCase(moonData?.sign)}.mp3`,
          type: 'audio/mp3'
        },
        {
          src: URL + '/7-phase.mp3',
          type: 'audio/mp3'
        },
        {
          src: URL + `/8-your-phase/${toKebabCase(moonData?.phase)}.mp3`,
          type: 'audio/mp3'
        },
        {
          src: URL + '/9-your-phase.mp3',
          type: 'audio/mp3'
        },
        {
          src: URL + '/10-book.mp3',
          type: 'audio/mp3'
        },
        {
          src: URL + '/11-book.mp3',
          type: 'audio/mp3'
        },
        {
          src: URL + '/12-book.mp3',
          type: 'audio/mp3'
        },
        {
          src: URL + '/13-book.mp3',
          type: 'audio/mp3'
        },
      ])

      setTracks(vttData);
    }
  }, [moonData, setMoonPhase, setMoonSign, setSources, setTracks, welcomeData, vttData, URL])

  return (
    <div className="calculating">
      <Moon />
      <ReactTextTransition>
        {phrases[LoadingIndex]}
      </ReactTextTransition>
      <ToastContainer />
    </div>
  );
}

export default Calculating;
