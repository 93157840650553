import axios from "axios";

async function fetchAndParseVTT(url) {
  try {
    const response = await axios.get(url);
    const vttText = await response.data;
    return parseVTT(vttText);
  } catch (error) {
    console.error('Error fetching or parsing VTT file:', JSON.stringify(error, null, 2));
    throw error;
  }
}

function parseVTT(vttText) {
  try {
    const lines = vttText.split('\n');
    const entries = [];
    let entry = {};

    for (let line of lines) {
      if (line.trim() === '' && entry.text) {
        entries.push(entry);
        entry = {};
      } else if (line.includes('-->')) {
        const [start, end] = line.split('-->');
        entry.start = start.trim();
        entry.end = end.trim();
      } else if (entry.start) {
        if (entry.text) {
          entry.text += '\n' + line.trim();
        } else {
          entry.text = line.trim();
        }
      }
    }

    if (entry.text) {
      entries.push(entry);
    }

    return entries;
  } catch (error) {
    console.error('Error parsing VTT text:', JSON.stringify(error, null, 2));
    throw error;
  }
}

export async function convertVTTsToJSON(urls) {
  const promises = urls.map(url => fetchAndParseVTT(url));
  const results = await Promise.all(promises);

  return results;
}


function parseTimeToSeconds(time) {
  const [hours, minutes, seconds] = time.split(':');
  const [sec, milli] = seconds.split('.');

  return (+hours) * 3600 + (+minutes) * 60 + (+sec) + (+milli) / 1000;
}

export function findSubtitleAtTime(subtitles, timeInSeconds) {
  if (!subtitles) return

  return subtitles.find(subtitle => {
    const startInSeconds = parseTimeToSeconds(subtitle.start);
    const endInSeconds = parseTimeToSeconds(subtitle.end);

    return timeInSeconds >= startInSeconds && timeInSeconds <= endInSeconds;
  });
}
