import styled from "styled-components";
import StepTitle from "../elements/StepTitle";
import SignCard from "../elements/SignCard";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0;
  gap: 25px;
`

export default function TimeQuestion() {
  const navigate = useNavigate()

  return (
    <div>
      <StepTitle back="/year-month">
        Etapa 4: Você sabe sua hora de nascimento?
      </StepTitle>
      <Container>
        <SignCard
          style={{textAlign: 'left'}}
          text="Sim"
          width={'100%'}
          onClick={() => navigate("/birth-time", { replace: true })}
          id="time-yes"
        />
        <SignCard
          style={{textAlign: 'right'}}
          text="Não"
          width={'100%'}
          onClick={() => navigate("/location", { replace: true }) }
          id="time-no"
        />
      </Container>
    </div>
  );
}
