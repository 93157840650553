import styled from "styled-components";
import SignCard from "../elements/SignCard";
import { useBackgroundContext } from "../../contexts/Background";
import { useNavigate } from "react-router-dom";
import { useStepsContext } from "../../contexts/Steps";
import StepTitle from "../elements/StepTitle";
import { useEffect } from "react";
import isDev from "../../helpers/environment";

const Featured = styled.span`
  text-decoration: underline;
  text-transform: uppercase;
`

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 360px;
  margin: 0 auto;
`

function SignPicker() {
  const { day, setSign } = useStepsContext()
  const { play } = useBackgroundContext()

  const navigate = useNavigate();

  useEffect(() => {
    if (day && !isDev()) window.location.reload();
  }, [day]);

  const handleClick = sign => {
    play()
    setSign(sign)
    navigate("day")
  }

  return (
    <div>
      <h2>
        Sua <strong>leitura da lua personalizada <Featured>gratuita</Featured></strong> explora as profundezas
        secretas de sua personalidade, relacionamentos e verdadeiro propósito
        na vida.
      </h2>
      <StepTitle back={false}>
        Etapa 1: Selecione seu signo do zodiaco
      </StepTitle>
      <CardsContainer>
        <SignCard image={`${process.env.REACT_APP_CDN_URL}/assets/images/signs/aries.png`} onClick={() => handleClick('aries')} id="sign-aries">Áries</SignCard>
        <SignCard image={`${process.env.REACT_APP_CDN_URL}/assets/images/signs/taurus.png`} onClick={() => handleClick('taurus')} id="sign-taurus">Touro</SignCard>
        <SignCard image={`${process.env.REACT_APP_CDN_URL}/assets/images/signs/gemini.png`} onClick={() => handleClick('gemini')} id="sign-gemini">Gêmeos</SignCard>
        <SignCard image={`${process.env.REACT_APP_CDN_URL}/assets/images/signs/cancer.png`} onClick={() => handleClick('cancer')} id="sign-cancer">Câncer</SignCard>
        <SignCard image={`${process.env.REACT_APP_CDN_URL}/assets/images/signs/leo.png`} onClick={() => handleClick('leo')} id="sign-leo">Leão</SignCard>
        <SignCard image={`${process.env.REACT_APP_CDN_URL}/assets/images/signs/virgo.png`} onClick={() => handleClick('virgo')} id="sign-virgo">Virgem</SignCard>
        <SignCard image={`${process.env.REACT_APP_CDN_URL}/assets/images/signs/libra.png`} onClick={() => handleClick('libra')} id="sign-libra">Libra</SignCard>
        <SignCard image={`${process.env.REACT_APP_CDN_URL}/assets/images/signs/scorpio.png`} onClick={() => handleClick('scorpio')} id="sign-scorpio">Escorpião</SignCard>
        <SignCard image={`${process.env.REACT_APP_CDN_URL}/assets/images/signs/sagittarius.png`} onClick={() => handleClick('sagittarius')} id="sign-sagittarius">Sagitário</SignCard>
        <SignCard image={`${process.env.REACT_APP_CDN_URL}/assets/images/signs/capricorn.png`} onClick={() => handleClick('capricorn')} id="sign-capricorn">Capricórnio</SignCard>
        <SignCard image={`${process.env.REACT_APP_CDN_URL}/assets/images/signs/aquarius.png`} onClick={() => handleClick('aquarius')} id="sign-aquarius">Aquário</SignCard>
        <SignCard image={`${process.env.REACT_APP_CDN_URL}/assets/images/signs/pisces.png`} onClick={() => handleClick('pisces')} id="sign-pisces">Peixes</SignCard>
      </CardsContainer>
    </div>
  );
}

export default SignPicker;
