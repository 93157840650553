function importAll(r) {
  return r.keys().map(r);
}

const localImages = importAll(
  require.context('/public/assets/images/animations', false, /\.(png|jpe?g|svg|gif)$/)
);

export const animationImages = localImages.map((imagePath) => {
  return imagePath.replace('/public', '');
});

