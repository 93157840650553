import styled from "styled-components";

const Content = styled.div`
  max-width: 600px;
`

function Main({ children }) {
  return (
    <main>
      <Content>
        {children}
      </Content>
    </main>
  );
}

export default Main;
