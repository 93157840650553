import styled from "styled-components";

const Card = styled.div`
  cursor: default;
`

const Image = styled.div`
  height: 80px;
  background-color: #ffffff;
  border-radius: 10px;

  &:hover {
    background-color: #3e2053;
    cursor: pointer;
  }
`

const Title = styled.p`
  margin: .2em;
  font-size: .8em;
`

const BigText = styled.p`
  font-size: 28px;
  line-height: 80px;
  vertical-align: middle;
  margin: 0;

  &:hover {
    color: #FFFFFF;
  }
`

function SignCard({children, image, text, onClick, width, id}) {
  return (
    <Card style={{width: width || '80px'}}>
      <Image onClick={onClick} style={{width: width || '80px'}} id={id}>
        {image && <img src={image} alt={children} width={75} />}
        {text && <BigText>{text}</BigText>}
      </Image>
      <Title>{children}</Title>
    </Card>
  )
}

export default SignCard;