import Select from 'react-select';

const getCustomTheme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#c5bccb',
    primary50: '#9e8fa9',
    primary75: '#776286',
    primary: '#3e2053',
  },
})

export default function ReactSelect(props) {
  return (
    <Select
      { ...props }
      isClearable
      isSearchable
      loadingMessage={() => 'Carregando...'}
      noOptionsMessage={() => 'Não há opções disponíveis'}
      theme={getCustomTheme}
    />
  )
}