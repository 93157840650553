import { useEffect } from 'react';
import { animationImages } from '../../../helpers/animationImages';

export default function PreloadImages() {
  useEffect(() => {
    const loadImages = (imageArray) => {
      imageArray.forEach((url) => {
        const img = new Image();
        img.src = process.env.REACT_APP_CDN_URL + url;
      });
    };

    loadImages(animationImages);
  }, []);

  return null;
}
