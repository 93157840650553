import Header from '../modules/Header';
import Main from '../modules/Main';
import Footer from '../modules/Footer';

import { Outlet } from 'react-router-dom';

export default function Default() {
  return (
    <>
      <Header />
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </>
  );
}
