import { useQuery } from "@tanstack/react-query";
import { getCities, getCountries, getStates } from "../../services/locationApi";
import { useStepsContext } from "../../contexts/Steps";

import StepTitle from "../elements/StepTitle";
import ReactSelect from "../elements/ReactSelect";
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";

export default function Location() {
  const navigate = useNavigate()
  const { country, state, city, setCountry, setState, setCity } = useStepsContext()

  const {data: countries, isLoading: loadingCountries} = useQuery({ queryKey: ['countries'], queryFn: getCountries })
  const {data: states, isLoading: loadingStates} = useQuery({ queryKey: ['states', country], queryFn: getStates })
  const {data: cities, isLoading: loadingCities} = useQuery({ queryKey: ['cities', state], queryFn: getCities })

  const handleCountryChange = async(selected) => {
    await setCountry(selected)
    await setState(null)
    await setCity(null)
  }

  const handleStateChange = async(selected) => {
    await setState(selected)
    await setCity(null)
  }

  const handleContinue = () => {
    navigate('/name', { replace: true })
  }

  return (
    <div>
      <StepTitle back="/time">
        Etapa 6: Selecione seu local de nascimento
      </StepTitle>
      <p>
        Para uma leitura mais precisa, escolha a cidade onde você nasceu. Se
        você não conseguir encontrar sua cidade, escolha a cidade vizinha mais
        próxima.
      </p>
      <div className="text-start">
        <ReactSelect
          options={countries}
          placeholder="País de nascimento"
          isLoading={loadingCountries}
          onChange={handleCountryChange}
          value={country}
          id="country-selector"
          formatOptionLabel={country => (
            <div className="country-option">
              <img src={country.image} alt={`Bandeira do ${country.label}`} />&nbsp;
              <span>{country.label}</span>
            </div>
          )}
        />
        <br />
        {country && (
          <>
            <ReactSelect
              options={states}
              placeholder="Estado"
              isLoading={loadingStates}
              onChange={handleStateChange}
              value={state}
              id="state-selector"
            />
            <br />
          </>
        )}
        {country && state && (
          <>
            <ReactSelect
              options={cities}
              placeholder="Cidade de nascimento"
              isLoading={loadingCities}
              onChange={(selected) => setCity(selected)}
              value={city}
              id="city-selector"
            />
            <br />
          </>
        )}
      </div>
      {country && state && city && (
        <Button onClick={handleContinue} id="location-continue">Continuar</Button>
      )}
    </div>
  )
}