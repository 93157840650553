import React, { forwardRef, useImperativeHandle } from 'react';
import { IMask, useIMask } from 'react-imask';
import Form from 'react-bootstrap/Form';

const TimeInput = forwardRef((props, ref) => {
  const { ref : inputRef } = useIMask({
    mask: 'HH:MM',
    blocks: {
      HH: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 23,
        maxLength: 2,
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
        maxLength: 2,
      },
    },
    overwrite: true,
    autofix: true,
    unmask: false
  });

  useImperativeHandle(ref, () => ({
    value: inputRef.current ? inputRef.current.value : '',
    focus: () => inputRef.current && inputRef.current.focus()
  }));

  return (
    <Form.Control
      type="text"
      placeholder="19:31"
      ref={inputRef}
      inputMode="numeric"
      pattern="[0-9]*"
      onInput={props.onInput}
      isInvalid={props.error}
      className="text-center fs-2 mb-3"
      id="time-input"
    />
  );
});

export default TimeInput;
