import StepTitle from "../elements/StepTitle";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { useStepsContext } from "../../contexts/Steps";
import { Bounce, toast, ToastContainer } from "react-toastify";

export default function Name() {
  const { setName } = useStepsContext()
  const [error, setError] = useState(false)
  const navigate = useNavigate();
  const nameRef = useRef();

  const handleInput = () => {
    setError(false)
    nameRef.current.value = nameRef.current.value.replace(/\s+/g, '');
  }

  const preventSpaces = event => {
      if (event.key === ' ') {
          event.preventDefault();
      }
  }

  const handleContinue = () => {
    const value = nameRef.current.value;
    const regex = /^[a-zA-ZÀ-ÖØ-öø-ÿ]{3,}$/;

    if (value && regex.test(value)) {
      setName(value)
      navigate('/calculating', { replace: true });
    } else {
      setError(true)
      nameRef.current?.focus()

      toast.error('Por favor, insira seu primeiro nome.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      })
    }
  }

  return (
    <div>
      <StepTitle back="/time">
        Etapa 7: Qual é seu primeiro nome?
      </StepTitle>
      <p>
        Isso nos ajuda a tornar essa leitura ainda mais pessoal!
      </p>
      <div className="text-start my-3">
        <Form.Label htmlFor="name">Primeiro nome:</Form.Label>
        <Form.Control
          ref={nameRef}
          id="name"
          aria-describedby="name"
          placeholder="Introduza seu primeiro nome"
          onInput={handleInput}
          onKeyDown={preventSpaces}
          autoComplete="off"
          isInvalid={error}
        />
      </div>
      <Button onClick={handleContinue} id="name-continue">Continuar</Button>
      <ToastContainer />
    </div>
  )
}